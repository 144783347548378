import me from '../../../assets/images/me.jpg'
import rocket from '../../../assets/images/rocket_vector.png'
import astronaut from '../../../assets/images/astronaut_vector.png'
import { Fade } from 'react-awesome-reveal'

import './style.css'

function About() {
    return(
        <section id="about_me" className='section w-100 ov-hidden'>
            <div className='container'>
                <Fade direction='up' triggerOnce={true}>
                    <div className='section-title'>
                        <span className='text-secondary'>01. </span>
                        <span>About Me</span>
                    </div>

                    <div className='info'>
                        <img src={me} alt="Profile" title="That's me! :)" />
                        <span className='presentation-text'>
                            <span>
                                I am a <span className='text-secondary'>Front-end developer</span> with over <span className='text-secondary'>5 years</span> of experience.
                            </span>
                            <span>
                                In recent years, I have <span className='text-secondary'>specialized</span> in web development using popular <span className='text-secondary'>frameworks</span> in the market, such as <span className='text-secondary'>React</span> and <span className='text-secondary'>Vue.js</span>.
                            </span>
                            <span>
                                I also have <span className='text-secondary'>experience</span> as a Full-stack developer, which has provided me with knowledge in both relational and non-relational <span className='text-secondary'>databases</span>, as well as back-end languages like <span className='text-secondary'>PHP</span>. 
                            </span>
                            <span>
                                Furthermore, I am a creative professional, highly skilled in <span className='text-secondary'>CSS</span>, and have a strong <span className='text-secondary'>passion</span> for interface design and <span className='text-secondary'>user experiences</span>.
                            </span>
                            <span>
                                This combination of skills enables me to design and develop <span className='text-secondary'>elegant</span> and <span className='text-secondary'>intuitive</span> interfaces, making user interaction with products a pleasant experience.
                            </span>
                            <span>
                                I <span className='text-secondary'>believe</span> that my passion for Front-end programming, coupled with my keen eye for design, empowers me to create <span className='text-secondary'>impactful solutions</span> that will drive the <span className='text-secondary'>success</span> of any company.
                            </span>
                        </span>
                    </div> 
                </Fade>
            </div>
            <img src={astronaut} alt="Astronaut Vector" className='astronaut hide-mobile' title="Yooo!" />
            <img src={rocket} alt="Rocket Vector" className='rocket hide-mobile' title="TO INFINITY AND BEYOND!"/>
        </section>
    )
}

export default About
import dexian_logo from '../../../assets/images/dexian.jpeg'
import ac_logo from '../../../assets/images/avenue_code.webp'
import extranet_logo from '../../../assets/images/extranet_logo.jpeg'
import ch_logo from '../../../assets/images/ch_logo.jpeg'
import TimelineItem from '../../atoms/timelineItem';

import './style.css'

function Timeline() {

    const ac_text = `I was part of the Front-end development team at Avenue Code.

    I worked on the successful Easy Tracking project for Stellantis. After the squad took over the project, we managed to turn around a very negative situation and became an internal success case within the company, with the product becoming a reference in Tracking for the client.

    Within Stellantis, I also worked with the team that worked on the Jeep Trilhas and Stellantis Training products.

    Additionally, I was involved in the AC Tech Leads project, where we developed important internal projects for the company's growth, such as AC Interviews.`

    const ac_stacks =`React - Vue.JS - Javascript - HTML - CSS - Bootstrap - MySQL - MongoDb - Jest - Docker - GitLab`

    const dx_text = `I'm part of the development team at Dexian.
    
    I work as a UI Developer for Norwegian Cruise Line. I'm responsible for assisting in the creation of the company's component library, maintaining the Oceania Brand website, and developing interfaces in general.`

    const dx_stacks = `Vue.JS - React - Javascript - HTML - CSS - StoryBook - Bootstrap`

    const rp_text = `I started as a Full-stack developer, but eventually became responsible for leading the Layout team at the company, overseeing quality control for all Front-end projects developed by the team members, and consistently introducing innovations in UX and UI. 
    
    I also worked on a standardization document for UX and UI guidelines for developers, participated in client calls directly, and faithfully implemented layouts designed by the Design team.`

    const rp_stacks =`PHP - Javascript - HTML - CSS - Bootstrap - MySQL - JQuery - AJAX`

    const ch_text =`I was responsible for the local network infrastructure, ensuring the proper functioning of machines, and maintaining a well-organized work environment.`

    return(
        <div className='timeline'>
            <TimelineItem logo={ dexian_logo } role="UI Developer" date="2023 - Now" company="Dexian" text={ dx_text } techs={ dx_stacks }/>
            <TimelineItem logo={ ac_logo } role="Front-end Engineer" date="2021 - 2023" company="Avenue Code" text={ ac_text } techs={ ac_stacks }/>
            <TimelineItem logo={ extranet_logo } role="Full-stack Developer" date="2018 - 2021" company="Franquia Extranet" text={ rp_text } techs={ rp_stacks }/>
            <TimelineItem logo={ ch_logo } role="Intern" date="2018" company="CH Master Data" text={ ch_text }/>
        </div>
    )
}

export default Timeline;
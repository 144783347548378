import Header from '../../shared/components/organisms/header/index.js'
import About from '../../shared/components/organisms/about/index.js';
import Navbar from '../../shared/components/molecules/navbar/index.js';
import Experiences from '../../shared/components/organisms/experiences/index.js';
import Projects from '../../shared/components/organisms/projects/index.js';
import Footer from '../../shared/components/organisms/footer/index.js';

function Home() {
    return (
        <div id="home">
            <Navbar />
            <div className='container'>
                <Header />
            </div>
            <About />
            <div className='bg-secondary'>
                <div className='container'>
                    <Experiences />
                </div>
            </div>
            <Projects />
            <Footer />
        </div>
    )
}

export default Home;

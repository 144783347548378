import './App.css';
import Routes from './app/shared/routes';

function App() {
  console.log(`I hope my work has inspired you in some way! Feel free to get in touch with me if you need anything :)`)

  return (
    <div className="app">
      <Routes />
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { FaLinkedin, FaEnvelope, FaWhatsapp } from "react-icons/fa6";

import './style.css'

function Menu() {
    const [isMobileMenuChecked, setIsMobileMenuChecked] = useState(false);

    const handleLinkClick = () => {
        setIsMobileMenuChecked(false);
    };
    return(
        <div className='menuWrapper'>
            <input type='checkbox' id='menu_mobile' checked={isMobileMenuChecked} onChange={() => setIsMobileMenuChecked(!isMobileMenuChecked)}/>
            <div>
                <label htmlFor='menu_mobile' className='burgerIcon'>
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
                <div className="menu">
                    <div className='menu-items'>
                        <a href='#about_me' onClick={handleLinkClick}>
                            <span>01. </span> About Me
                        </a>
                        <a href='#experiences' onClick={handleLinkClick}>
                            <span>02. </span> Work Experiences
                        </a>
                        <a href='#projects' onClick={handleLinkClick}>
                            <span>03. </span> Projects
                        </a>
                    </div>

                    <div className='mobile-contact'>
                        <a href="https://www.linkedin.com/in/wagnerabramo/" target="_blank" rel="noreferrer">
                            <FaLinkedin />
                        </a>        
                        <a href="mailto:wagnerabramo@hotmail.com">
                            <FaEnvelope />
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5532984488428&text=Hey%2C+I+came+from+your+online+portfolio+and+I+would+like+to+get+in+touch+with+you." target="_blank" rel="noreferrer">
                            <FaWhatsapp />
                        </a>                        
                    </div>
                </div>
                <label htmlFor='menu_mobile' className='menu-backdrop' />
            </div>
        </div>
    )
}

export default Menu;
import './style.css'

function Logo() {
    return (
        <a className="logo" href='#home'>
            <span className='text-secondary'>{'<'}</span>
            <span>W</span>
            <span className='text-secondary'>.A</span>
            <span> portfolio</span>
            <span className='text-secondary'>{'>'}</span>
        </a>
    )
}

export default Logo;
import Timeline from '../../molecules/timeline'
import { Fade } from 'react-awesome-reveal'

import './style.css'

function Experiences() {
    return(
        <section id="experiences" className='section'>
            <Fade triggerOnce={true} direction='left'>
                <div className='section-title'>
                    <span className='text-secondary'>02. </span>
                    <span>Work Experiences</span>
                </div>
            </Fade>
            <Timeline />
        </section>
    )
}

export default Experiences
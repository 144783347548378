import Banner from '../banner/index.js'
import laptop_vector from'../../../assets/images/laptop_vector.png'
import './style.css'

function Header() {
    return (
        <section className='header'>
            <div className='header-bg'>
                <img className='laptop-img hide-mobile' src={ laptop_vector } alt="Laptop" />
            </div>
            <Banner />
        </section>
    )
}

export default Header;
import { Fade } from "react-awesome-reveal";

import './style.css'

function TimelineItem(props) {
    return(
        <div className='timeline-item'>           
            <div className='timeline-icon'>
                <img src={ props.logo } alt="Avenue Code Logo"/>
            </div>
            <div className='timeline-info'>
                <Fade triggerOnce={true}>
                    <div>
                        <span className="timeline-role text-secondary">
                            { props.role }
                        </span>
                        <span className="timeline-date">
                            { props.company } . { props.date }
                        </span>
                    </div>
                </Fade>
                <Fade triggerOnce={true} className='timeline-text'>{ props.text }</Fade>
                {props.techs && (
                    <div className='timeline-techs'>
                        <Fade triggerOnce={true} className='text-secondary'>Tech Stacks:</Fade>
                        <Fade triggerOnce={true}>{props.techs}</Fade>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TimelineItem
import { Fade } from "react-awesome-reveal";
import { FaLinkedin, FaEnvelope, FaWhatsapp } from "react-icons/fa6";

import './style.css'

function Footer() {
    return (
        <>
            <div className="container">
                <section id="footer" className='section w-100'>
                    <Fade cascade damping={0.1} triggerOnce={true}>
                        <div className='section-title'>
                            <span>Get in <span className='text-secondary'>Touch!</span></span>
                        </div>
                        <div className="subtitle">
                            Did you enjoy my work? Feel free to get in touch with me!
                        </div>
                    </Fade>

                    <div className="contact" id="contact">
                        <Fade cascade damping={0.1}>
                            <div>
                                <span className="contact-icon text-secondary">
                                    <FaLinkedin />
                                </span>
                                <a href="https://www.linkedin.com/in/wagnerabramo/" target="_blank" rel="noreferrer">wagnerabramo</a>
                            </div>
                            <div>
                                <span className="contact-icon text-secondary">
                                    <FaEnvelope />
                                </span>
                                <a href="mailto:wagnerabramo@hotmail.com">wagnerabramo@hotmail.com</a>
                            </div>
                            <div>
                                <span className="contact-icon text-secondary">
                                    <FaWhatsapp />
                                </span>
                                <a href="https://api.whatsapp.com/send?phone=5532984488428&text=Hey%2C+I+came+from+your+online+portfolio+and+I+would+like+to+get+in+touch+with+you." target="_blank" rel="noreferrer">+55 32 98448-8428</a>
                            </div>
                        </Fade>
                    </div>
                </section>
            </div>
            <footer>
                <span><span className="text-secondary">Designed</span> and <span className="text-secondary">developed</span> by <span className="text-secondary">me</span>.</span>
            </footer>
        </>
    )
}

export default Footer;
import { Navigate, Route, Routes as Switch } from "react-router-dom"
import { BrowserRouter } from "react-router-dom"
import Home from "../../pages/home/index"

function Routes() {
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/portfolio" element={<Home />} />
                <Route path="*" element={<Navigate to="/portfolio" />} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;
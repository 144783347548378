import Card from '../../molecules/card';
import { Fade } from 'react-awesome-reveal'

import futrading from '../../../assets/images/futrading.png'
import olho_no_lance from '../../../assets/images/olho_no_lance.png'
import planet_vector from '../../../assets/images/planet_vector.png'
import blackhole_vector from '../../../assets/images/blackhole_vector.png'

import './style.css'

function Projects() {
    return(
        <section id="projects" className='section w-100 center'>
            <div className='container'>
                <Fade triggerOnce={true} direction='right'>
                    <div className='section-title'>
                        <span className='text-secondary'>03. </span>
                        <span>Projects</span>
                    </div>
                </Fade>

                <Fade triggerOnce={true}>
                    <div className='card-group'>
                        <Card title="Futrading" img={futrading} link="https://futrading.com/" />
                        <Card title="Olho no Lance" img={olho_no_lance} link="https://www.oolhonolance.com.br/home" />
                    </div>
                </Fade>
            </div>

            <img src={planet_vector} className='planet hide-mobile' alt="Planet Vector" title="Just a planet u.u"/>
            <img src={blackhole_vector} className='blackhole hide-mobile' alt="Black Hole Vector" title="You spin my head right round, right round..."/>
        </section>
    )
}

export default Projects;
import { Fade } from "react-awesome-reveal";


import './style.css'

function Banner() {
    return (
        <div className="banner">
            <div>
                <Fade cascade triggerOnce={true} className="info">
                    <span className="font-xl bold">Hello, I'm Wagner</span>
                    <span className="font-lg bold text-secondary">Front-end Developer</span>
                    <span className="title-text">Passionate about creating impactful solutions.</span>
                    <a href="#contact">Contact me!</a>
                </Fade>
            </div>
        </div>
    )
}

export default Banner;
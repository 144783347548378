import { MdOutlineRocketLaunch } from "react-icons/md";
import './style.css'

function Card(props) {
    return(
        <div className='card'>
            <div className='card-img'>
                <img src={props.img} alt="card" />
            </div>
            <div className='card-title'>
                <span>{props.title}</span>
            </div>
            <div className='card-footer'>
                <span>{props.title}</span>
                <a href={props.link} target="_blank" rel="noreferrer">Visit <span><MdOutlineRocketLaunch /></span></a>
            </div>
        </div>
    )
}

export default Card;
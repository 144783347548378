import React, { useState, useEffect } from 'react';
import Logo from "../../atoms/logo"
import Menu from "../menu"
import './style.css'

function Navbar() {
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={scrollY >= 100 ? 'navbar-bg' : ''}>
            <div className='container'>
                <div className='navbar'>
                    <Logo />
                    <Menu />
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
